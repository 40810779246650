import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleTabs } from 'components';
import {
    ServiceType,
    systemItemStyles,
    SystemItemHeader,
    Departments,
    Credential,
    JobTitles,
    PayrollSetup,
    Company,
    Calendars,
} from './core';
import { PlaceOfService } from './core/placeOfService';
import { tabsLabels } from './constants';
import { PermissionList, RolePermission, SaveParams } from 'utils';
import { useHistory } from 'react-router-dom';
import { httpRequestsOnErrorsActions } from '../../store';

export const SystemItem = ({}) => {
    const classes = systemItemStyles();
    const globalCredentials = useSelector((state) => state.system.credentials);
    const globalDepartments = useSelector((state) => state.system.departments);
    const globalJobs = useSelector((state) => state.system.jobs);
    const globalPayCodes = useSelector((state) => state.payroll.payCodes);
    const globalOvertimeSettings = useSelector((state) => state.payroll.overtimeSettings);
    const globalPlaces = useSelector((state) => state.system.places);
    const [deleteModalOpened, setDeleteModalOpened] = useState(false);
    const [deletedId, setDeletedId] = useState('');
    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalInformation, setModalInformation] = useState('');
    const [deletedName, setDeletedName] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;

    function handleOpenClose(modalType, modalInformation) {
        setModalType(modalType);
        setModalInformation(modalInformation);
        setOpen((prevState) => !prevState);
    }

    function handleDeletedOpenClose() {
        setDeleteModalOpened(false);
    }

    function handleRemoveItem(data) {
        setDeleteModalOpened(true);
        setDeletedId(data.id);
        setDeletedName(data.name);
        setModalType(data.type);
    }

    const navigateTab = () => {
        const params = {
            ...info,
        };
        delete params.sortType;
        delete params.type;
        delete params.status;

        history.push('/system', {
            state: {
                ...params,
                activeTab: 'Calendar Integration',
            },
        });
    };

    useEffect(() => {
        if (history?.location?.pathname === '/system/success' && info?.activeTab !== 'Calendar Integration') {
            navigateTab();
        }
        if (history?.location?.pathname === '/system/forbidden' && info?.activeTab !== 'Calendar Integration') {
            dispatch(httpRequestsOnErrorsActions.appendError('CALENDAR_INTEGRATION_FORBIDDEN'));
            navigateTab();
        }
        if (history?.location?.pathname === '/system/wrong' && info?.activeTab !== 'Calendar Integration') {
            dispatch(httpRequestsOnErrorsActions.appendError('CALENDAR_WRONG_INTEGRATION'));
            navigateTab();
        }
    }, [info]);

    const tabsContent = [
        {
            tabComponent: (
                <Company />
            ),
            tab: 'Company Profile',
        },
        RolePermission([PermissionList.SYSTEM_SERVICE_TYPES?.code]) &&
        {
            tabComponent: (
                <ServiceType />
            ),
            tab: 'Service Types',
        },
        RolePermission([PermissionList.SYSTEM_CREDENTIALS?.code]) &&
        {
            tabComponent: (
                <Credential
                    globalCredentials={globalCredentials}
                    openModal={handleOpenClose}
                />
            ),
            tab: 'Credentials',
        },
        RolePermission([PermissionList.SYSTEM_DEPARTMENTS?.code]) &&
        {
            tabComponent: (
                <Departments
                    globalDepartments={globalDepartments}
                />
            ),
            tab: 'Departments',
        },
        RolePermission([PermissionList.SYSTEM_JOB_TITLES?.code]) &&
        {
            tabComponent: (
                <JobTitles
                    globalJobs={globalJobs}
                />
            ),
            tab: 'Job Titles',
        },
        RolePermission([
            PermissionList.SYSTEM_PAYCODE_TYPES?.code,
            PermissionList.SYSTEM_MILEAGE?.code,
            PermissionList.SYSTEM_OVERTIME?.code,
        ]) &&
        {
            tabComponent: (
                <PayrollSetup
                    globalPayCodes={globalPayCodes}
                    globalOvertimeSettings={globalOvertimeSettings}
                />
            ),
            tab: 'Payroll Setup',
        },
        RolePermission([PermissionList.SYSTEM_PLACE_OF_SERVICE?.code]) &&
        {
            tabComponent: (
                <PlaceOfService
                    globalJobs={globalPlaces}
                    removeItem={handleRemoveItem}
                />
            ),
            tab: 'Place of Service',
        },
        {
            tabComponent: (
                <Calendars />
            ),
            tab: 'Calendar Integration',
        },
    ];

    return (
        <div className={classes.systemItemWrapper}>
            <SystemItemHeader
                deletedName={deletedName}
                modalInformation={modalInformation}
                deletedId={deletedId}
                deleteModalOpened={deleteModalOpened}
                handleDeletedOpenClose={handleDeletedOpenClose}
                modalType={modalType}
                open={open}
                handleOpenClose={handleOpenClose}
            />
            <SimpleTabs
                defaultTab={
                    history?.location?.pathname === '/system/forbidden' ? 'Calendar Integration' :
                        history?.location?.pathname === '/system/success' ? 'Calendar Integration' :
                            history?.location?.pathname === '/system/wrong' ? 'Calendar Integration' :
                                info?.activeTab === "Calendar Integration" ?  "Calendar Integration" :
                                info?.state?.activeTab === "Calendar Integration" ?  "Calendar Integration" :
                                'Company Profile'}
                tabsLabels={tabsLabels}
                tabsContent={tabsContent}
            />
        </div>
    );
};
